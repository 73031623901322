import React from 'react';
import './App.css';

import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import Home from './Home'
import ArtistPage from "./pages/ArtistPage";

function App() {
  return (
      <Router basename={process.env.PUBLIC_URL}>
        <div className="App">
          <Switch>
            <Route exact path="/" component={Home}/>
            <Route path = "/artist/:uid" component={ArtistPage}/>
            <Redirect to="/"/>
          </Switch>

        </div>

      </Router>
  );
}

export default App;
