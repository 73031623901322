import './Home.css'
import React, {Component} from 'react';
import logo from "./logo.png"
import {useHistory} from "react-router-dom"

import {CardColumns, Card, Jumbotron, Button} from "react-bootstrap";

import {artists} from './super_dir/artists'

class Home extends Component {
    componentDidMount() {
        document.title = "Home"
    }
    artistSection(){
        return(
            <CardColumns style={{marginLeft:"10%", marginRight:"10%"}}>
                {this.artistCards()}
            </CardColumns>
        )
    }
    artistCards(){
        var artistObjects = []
        for (const [key, value] of Object.entries(artists)) {
            value["uid"] = key;
            artistObjects.push(value);
        }

        artistObjects = artistObjects.sort(()=>Math.random()-0.5)
        let artistArrayLength = artistObjects.length
        const artistCards = []
        for (var i = 0; i < artistArrayLength; i++){
            let artist = artistObjects[i]

            artistCards.push(
                <Card>
                    <Card.Img variant="top" src={artist.work} />
                    <Card.Body>
                        <Card.Title>{artist.displayName}</Card.Title>
                        <Card.Text>
                            {artist.desc}
                        </Card.Text>
                        <Button variant="light" onClick={()=>this.props.history.push("/artist/" + artist.uid)}>Visit</Button>
                    </Card.Body>
                </Card>
            )
        }
        return artistCards
    }
    seasonDrop(){
        return(
            <p className="titleText" style={{marginTop:"120px",marginBottom:"120px"}}>coming soon...</p>
        )
    }

    visitTransparencyLink(){
        window.location="https://frzn.tray1mp.com/postersForHumanity/postersForHumanity2020Q1Q2Report.pdf";
    }

    render() {
        return(
            <div style={{backgroundColor:"#101010", height:"100%"}}>
                <img src={logo} style={{width:"30%", height:"auto", marginTop:"25%",marginBottom:"25%"}} draggable="false"/>
                <h1 className="titleText">This season's line:</h1>
                {this.seasonDrop()}
                <h1 className="titleText">Meet the artists for humanity:</h1>
                {this.artistSection()}

                <Jumbotron style={{marginTop:"10%",marginBottom:"10%"}}>
                    <h1>Transparency Commitment</h1>
                    <p>
                        We release transparency reports at the end of Q2 and Q4.
                    </p>
                    <p>
                        <Button onClick={()=>this.visitTransparencyLink()} variant="dark">See latest report: Q2 2020</Button>
                    </p>
                </Jumbotron>

                <a className="titleText" id="link" href="https://www.1mpressions.com" target="_blank">Copyright 2020 Posters for Humanity, an 1mpressions Company</a>

                <p style={{backgroundColor:"#101010"}}></p>

            </div>


        );
    }
}

export default Home