import React, {Component} from 'react';
import {artists} from '../super_dir/artists'
import {Button} from "react-bootstrap";

class ArtistPage extends Component {

    componentDidMount() {
        document.title = "Artist";
        const uid = this.props.match.params.uid;
        this.getArtistData(uid)


    }
    constructor(props){
        super(props)
        this.state = {
            artistObject: {

                displayName: "",
                work: "",
                desc: "",
                link1: "",
                link1Title: "",
                link2: "",
                link2Title: "",
                quote: ""


            }
        }
        this.getArtistData = this.getArtistData.bind(this)

    }
    getArtistData = (uid) =>{
        if (artists[uid]){
            this.setState({
                    artistObject: artists[uid]
                }
            )
        }
    }
    generateLinkOne(){
        if (this.state.artistObject.link1 != ""){
            return(
                <Button variant="light" onClick={()=>this.visitLinkOne()}>{this.state.artistObject.link1Title}</Button>
            )
        }
    }
    generateLinkTwo(){
        if (this.state.artistObject.link2 != ""){
            return(
                <Button variant="light" onClick={()=>this.visitLinkTwo()}>{this.state.artistObject.link2Title}</Button>
            )
        }
    }
    visitLinkOne(){
        window.location=this.state.artistObject.link1;
    }
    visitLinkTwo(){
        window.location=this.state.artistObject.link2;
    }

    render() {
        return(
            <div style={{backgroundColor:"#101010", height:"100%"}}>

                <img style={{marginTop:"40px", width:"35%", height:"auto"}} src={this.state.artistObject.work}/>
                <p></p>
                <div style={{backgroundColor:"orange", width:"75%", margin: "auto", padding: "30px", borderRadius:"5px"}}>
                    <h1 className="titleText">{this.state.artistObject.displayName}</h1>
                    <p className="titleText">{this.state.artistObject.desc}</p>

                    {this.generateLinkOne()}
                    <p></p>
                    {this.generateLinkTwo()}

                </div>
                <p></p>

            </div>
        )
    }
}
export default ArtistPage