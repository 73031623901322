const artists = {
    sharif:{
        displayName: "Sharif Rams",
        work: "https://cdn.shopify.com/s/files/1/0348/6977/2347/files/shariframs_large.jpeg?v=1592351143",
        desc: "My name is Sharif, and I shoot things in motion. I love to capture and showcase everyday life to my audience: be sure to check out my vlogs on youtube!",
        link1: "https://www.youtube.com/channel/UCNoi58xijWgfGhcx4yQrY2A",
        link1Title: "Sharif's Youtube",
        link2: "https://www.youtube.com/watch?v=tQXuw_Zi7ds",
        link2Title: "THIS GOT WILD!!!!!! (Youtube)",
        quote: ""

    },
    derek:{
        displayName: "Derek",
        work: "https://cdn.shopify.com/s/files/1/0348/6977/2347/files/Derek_large.png?v=1591308348",
        desc: "it's show time! Cheerful, energetic, and full of attitude. I bring you a daily dose of positive energy through my various drag performances. Come dance, sing, and vibe with me on Instagram!",
        link1: "https://www.instagram.com/drecked/",
        link1Title: "Instagram (@drecked)",
        link2: "",
        link2Title: "",
        quote: ""

    },
    kali:{
        displayName: "Kali",
        work: "https://cdn.shopify.com/s/files/1/0348/6977/2347/files/kali_large.jpeg?v=1591223244",
        desc: "Kali specializes in digital arts and creates pieces that go from highlighting everyday life to highlighting serious issues. She does this all while balancing school. At only 16, Kali shows us that age is probably not a limiting factor for supporting what's right. Using her art to spread awareness about the injustices faced by African-Americans, she hopes that people get educated about the racism plaguing America.",
        link1: "https://www.instagram.com/p/CA_KgNjHwrN/",
        link1Title: "We highly encourage our audience to read her post here",
        link2: "https://instagram.com/kalidraws_003",
        link2Title: "Instagram (@kalidraws_003)",
        quote: ""

    },
    terryCui:{
        displayName: "Terry Cui",
        work: "https://cdn.shopify.com/s/files/1/0348/6977/2347/files/TerrySway_large.png?v=1589810749",
        desc: "My name is Terry Cui and I make music sometimes.\n" +
            "A creative at heart, I've explored a variety of avenues from performance arts to painting. I hope to continue to impact the world through a creative lens.",
        link1: "https://www.instagram.com/sway.ttt/",
        link1Title: "Instagram (@swat.ttt)",
        link2: "",
        link2Title: "",
        quote: ""

    },
    sharadGadhia:{
        displayName: "Sharad Gadhia",
        work: "https://cdn.shopify.com/s/files/1/0348/6977/2347/files/Sharad_Gadhia_large.jpeg?v=1589482666",
        desc: "This photograph is how a lot of feel right now. isolated, alone, and a little bit uncertain about what comes next - and there's beauty in that.",
        link1: "https://sharadgadhia.com/",
        link1Title: "https://sharadgadhia.com/",
        link2: "https://www.instagram.com/sg.analog",
        link2Title: "Instagram (@sg.analog)",
        quote: ""

    },
    zoeYang:{
        displayName: "Zoe Yang",
        work: "https://cdn.shopify.com/s/files/1/0348/6977/2347/files/zoe_yang_large.jpg?v=1589426214",
        desc: "Creating art has always been a hobby of mine, and I love being able to share my creations with friends and family! This was a gift for my best friend who's moving far away, so that they'll always have a piece of home with them :)",
        link1: "https://www.instagram.com/mamayangcooks/",
        link1Title: "Instagram (@mamayangcooks)",
        link2: "Instagram (@zoopew)",
        link2Title: "Instagram (@zoopew)",
        quote: ""

    },
    arya:{
        displayName: "Arya",
        work: "https://cdn.shopify.com/s/files/1/0348/6977/2347/files/Arya_large.jpeg?v=1589381286",
        desc: "This is a piece I like to call \"distance\"",
        link1: "https://www.instagram.com/aryaghaeli/",
        link1Title: "Instagram (@aryaghaeli)",
        link2: "",
        link2Title: "",
        quote: ""

    },
    hajerMustafa:{
        displayName: "Hajer Mustafa",
        work: "https://cdn.shopify.com/s/files/1/0348/6977/2347/files/HajerMustafa_medium.jpg?v=1589381052",
        desc: "Hi, Im Hajer Mustafa! Im a photographer, visual artist, painter, and wanna-be fashion designer. Many of my works span themes ranging from interpersonal self-reflection to more grandiose, slice-of-life images. Im born and raised in an artistic family, which really allowed my creativity to flourish from a young age. Other than photography, I also love painting on clothes and tote bags as a hobby that has turned into a business, which has been really amazing. However, what I really wanted to showcase is some of my photography work. The image that you see here is part of a series I called Body Language, which is essentially an exploration into what emotions would look like without the use of facial expressions.",
        link1: "https://www.instagram.com/hajermustafaxx/",
        link1Title: "Instagram (@hajermustafaxx)",
        link2: "https://www.instagram.com/hajpaints/",
        link2Title: "Instagram (@hajpaints)",
        quote: ""

    },
    emilyYau:{
        displayName: "Emily Yau",
        work: "https://cdn.shopify.com/s/files/1/0348/6977/2347/files/EmilyYau_large.png?v=1588976567",
        desc: "An homage to my home city - the 6ix - and to fellow Torontonians! There's many things that make us feel like home, and there's something about this city that brings me pure happiness and excitement. Join me on my artistic journey through my Instagram - I'd love it for you to come along!",
        link1: "https://www.etsy.com/shop/EYAUstudio",
        link1Title: "Emily's Etsy",
        link2: "https://www.instagram.com/eyau.studio/",
        link2Title: "Instagram (@eyau.studio)",
        quote: ""

    },
    sandraNgenge:{
        displayName: "Sandra Ngenge",
        work: "https://cdn.shopify.com/s/files/1/0348/6977/2347/files/SandraNgenge_medium.jpeg?v=1588964020",
        desc: "Portrait of my close friend for her 20th birthday. Titled “Nya”. I am a painter based in Ottawa, completely self taught and still learning. I love art and hate sweet potatoes.",
        link1: "https://www.instagram.com/nejeart/",
        link1Title: "Instagram (@nejeart)",
        link2: "https://www.instagram.com/the.m_a_g/",
        link2Title: "Instagram (@the.m_a_g)",
        quote: ""

    }
    ,
    lukeLu:{
        displayName: "Luke Lu",
        work: "https://cdn.shopify.com/s/files/1/0348/6977/2347/files/LukeLu0_medium.png?v=1588725013",
        desc: "As a kid, I'd always get into trouble for doodling. If only Ms. Coy knew I'd be an artist for humanity ;) Follow me to keep up with my daily antics",
        link1: "https://www.instagram.com/lukelu98/",
        link1Title: "Instagram (@lukelu98)",
        link2: "https://lukelu43.wixsite.com/mysite",
        link2Title: "Luke's Website",
        quote: ""

    }

}


export {artists}